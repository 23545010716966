<template>
<div class="matches-view">
    <div class="action-selection__content" @touchstart="buttonsHidden = true" @touchend="buttonsHidden = false" @mousedown="buttonsHidden = true" @mouseup="buttonsHidden = false">

        <h1>{{ $t('Matches.title') }}</h1>
        <p>
            {{ $t('Matches.message') }}
        </p>

        <Tinder ref="tinder" key-name="id" :queue.sync="queue" :offset-y="10" @submit="onSubmit">
            <template slot-scope="scope">
                <div class="pic" :style="{
            'background-image': `url(${scope.data.url})`
          }" />
                <h2>
                    {{ scope.data.name }},
                    <template v-if="scope.data.age_of_coparticipant">
                        {{ scope.data.age }} & {{ scope.data.age_of_coparticipant }}
                    </template>
                    <template v-else>
                        {{ scope.data.age }}
                    </template>
                </h2>
                <p>{{ scope.data.city }}</p>

            </template>

            <div slot="like" class="like-pointer">
                <div class="icon">
                    <MatchAcceptIcon />
                </div>
            </div>

            <div slot="nope" class="nope-pointer">
                <div class="icon red">
                    <MatchDiscardIcon />
                </div>
            </div>

        </Tinder>
        <div class="buttons" :style="{opacity: buttonsHidden || queue.length == 0 ? 0 : 1}">
            <PtyButton id="pty__vk2wqdho1" light :circle="true" @click="decide('nope')" style="width: 70px; height: 70px;" :icon="true" shadow class="red">
                <MatchDiscardIcon />
            </PtyButton>

            <PtyButton id="pty__b03q29370" light :circle="true" @click="decide('like')" style="width: 70px; height: 70px;" :icon="true" shadow>
                <MatchAcceptIcon />
            </PtyButton>
        </div>
    </div>
</div>
</template>

<script>
// @ts-ignore
import * as Sentry from '@sentry/vue';

import PtyButton from '@/components/PtyButton.vue';

import Tinder from 'vue-tinder';

import {
    partecipantService,
    matchService,
    fileService
} from '@/rest';

import MatchAcceptIcon from '@/assets/images/icons/match_accept.svg';
import MatchDiscardIcon from '@/assets/images/icons/match_discard.svg';

export default {
    name: 'Matches',
    components: {
        Tinder,
        MatchAcceptIcon,
        MatchDiscardIcon,
        PtyButton
    },
    data: () => ({
        queue: [],
        offset: 0,
        buttonsHidden: false,
        matches: [],
        event: null,
        after: null
    }),

    methods: {

        async onSubmit({
            item,
            type,
        }) {
            console.log('onSubmit', item, type);
            try {
                const result = await matchService.edit({
                    matchId: item.id,
                    approve: type === 'like'
                });

                if (result.status != 200) {
                    throw new Error('Errore');
                }
            } catch (e) {
                Sentry.captureException(e);
                this.$vs.notification({
                    title: this.$t('Common.error'),
                    text: this.$t('Matches.error'),
                    color: 'primary', duration: 5000,
                    position: 'top-center'
                });
            }

            if(this.after != null && this.queue.length == 1) { // get more matches if after is not null and there is only 1 match left in the swiper
                await this.getMatches();
            }

            // finally check if there are other matches to show
            if (this.queue.length == 0) {
                this.goToChats();
            }
        },
        async decide(choice) {

            try {
                if (choice === 'like') {
                    document.querySelector('.tinder-card:first-child .like-pointer-wrap').style.opacity = 1
                } else {
                    document.querySelector('.tinder-card:first-child .nope-pointer-wrap').style.opacity = 1
                }
            } catch (e) {
                Sentry.captureException(e);
                console.error(e);
            }


            setTimeout(() => {
                this.$refs.tinder.decide(choice);
            }, 500);
        },

        async getMatches() {
            let matches = await matchService.get({
                limit: 3,
            });

            if (matches.data == null) {
                matches.data = [];
            }

            this.matches = this.matches.concat(matches.data);
            this.after = matches.after;

            for (let match of matches.data) {

                let url = '';
                try {
                    const fileResult = await fileService.getSignedDownloadURL({
                        name: match.participant.id,
                    });
                    url = fileResult.url;
                } catch (e) {
                    Sentry.captureException(e);
                    console.error(e);
                }

                this.queue.push({
                    id: match.id,
                    name: match.participant.nickname || 'Unknown',
                    age: match.participant.age || '-',
                    age_of_coparticipant: match.participant.age_of_coparticipant,
                    city: match.participant.city || 'Unknown',
                    url
                });
            }
        },

        goToChats() {
            // store into local storage that matches for event xxx have been answered at least 1 time
            localStorage.setItem('MATCHES_ANSWERED', JSON.stringify({
                event_id: this.event.id
            }));

            this.$router.push({
                name: 'Chats'
            });
        }
    },

    async mounted() {
        try {
            const p = await partecipantService.detail();
            this.event = p.event;
            if (p.event.status != 'IN_PROGRESS') {
                this.$router.push({
                    name: 'OnBoarding'
                });
            }
        } catch (e) {
            Sentry.captureException(e);
            console.error(e);
            this.$router.push({
                name: 'OnBoarding'
            });
        }

        await this.getMatches();
        if (this.matches.length == 0) {
            this.goToChats();
        }

    }
}
</script>

<style>

</style><style scoped>
.pic::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
    pointer-events: none;
    /* Ensure the gradient does not interfere with interactions */
}

.tinder-card h2 {
    color: white;
    position: absolute;
    bottom: 4rem;
    left: 10vw;
    z-index: 10;
}

.buttons {
    position: absolute;
    bottom: 2svh;
    z-index: 1000 !important;
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    gap: 30vw;
    width: 100%;
    transition: opacity 0.5s;

    /* filter drop shadow */
    filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.15));
}

.tinder-card p {
    color: white;
    position: absolute;
    bottom: 2.5rem;
    left: 10vw;
    z-index: 10;
}

.action-selection__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0rem;
    padding-top: 3rem;
}

h1 {
    color: #000;
    font-family: "Sofia Pro";
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: -0.0425rem;
    margin: 0;
}

p {
    color: #000;
    text-align: center;
    font-family: "Sofia Pro";
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 177.1%;
    /* 1.88169rem */
}

.matches-view {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    position: relative;
    align-items: center;
    overflow-x: hidden;
    flex-direction: column;
    gap: 1.5rem;
    background:
        linear-gradient(180deg, rgb(210, 251, 253) 16.57%, rgb(255, 255, 255) 53.63%);

}

#app .vue-tinder {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    bottom: 6svh;
    margin: auto;
    width: calc(100% - 20px);
    height: 65svh;
    min-width: 300px;
    max-width: 355px;
}

.nope-pointer,
.like-pointer {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nope-pointer {
    background: linear-gradient(360deg, #CF3E58 0%, rgba(255, 255, 255, 0.00) 100%);
}

.like-pointer {
    background: linear-gradient(360deg, #3EBDCF 0%, rgba(255, 255, 255, 0.00) 100%);
}

.nope-pointer .icon,
.like-pointer .icon {
    position: absolute;
    z-index: 1;
    top: calc(50% - 40px);
    left: calc(50% - 40px);
    width: 80px;
    height: 80px;
    background: #FFF;
    box-shadow: 0px 20px 45px 0px rgba(0, 0, 0, 0.25);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pic {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
}

.btns {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 30px;
    margin: auto;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 300px;
    max-width: 355px;
}

.btns img {
    margin-right: 12px;
    box-shadow: 0 4px 9px rgba(0, 0, 0, 0.15);
    border-radius: 50%;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

.btns img:nth-child(2n + 1) {
    width: 53px;
}

.btns img:nth-child(2n) {
    width: 65px;
}

.btns img:nth-last-child(1) {
    margin-right: 0;
}

.matches-view .red svg>* {
    fill: #CF3E58 !important;
}
</style>
